import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/common/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/common/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './services/auth/auth-guard.service';
import { LogoutComponent } from './views/sessions/logout/logout.component';
import { DisclaimerComponent } from './views/disclaimer/disclaimer.component';
import { CookiesComponent } from './views/cookies/cookies.component';
import { GdprComponent } from './views/gdpr/gdpr.component';
import { PatchnotesComponent } from './views/patchnotes/patchnotes.component';
import { UserActivationComponent } from './views/sessions/user-activation/user-activation.component';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';
import { TestcomponentComponent } from './views/testcomponent/testcomponent.component';
import { SigninComponent } from './views/sessions/signin/signin.component';
import { PrintLayoutComponent } from './views/rszoft/report/print-layout/print-layout.component';
import { DriverreportPrintComponent } from './views/rszoft/report/dailyChartComponents/driverreport-print/driverreport-print.component';
import { DrivertraceComponent } from './views/analysis/drivertrace/drivertrace.component';
import { WeeklyTablePrintComponent } from './views/rszoft/report/dailyChartComponents/weekly-table-print/weekly-table-print.component';
import { DataMatchPrintComponent } from './views/rszoft/report/data-match/data-match-print/data-match-print.component';
import { DriverAttestationPrintComponent } from './views/rszoft/report/driver-attestation/driver-attestation-print/driver-attestation-print.component';
import { ChecklistPrintComponent } from './views/rszoft/report/checklist/checklist-print/checklist-print.component';
import { UpdaterCheckerComponent } from './views/updater-checker/updater-checker.component';
import { NotificationManagementComponent } from './views/notification-management/notification-management.component';
import { MobileAppsComponent } from './views/mobile-apps/mobile-apps.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { ManageSubCompanyComponent } from './views/manage-sub-company/manage-sub-company.component';
import { ManageSubCompanyBillingComponent } from './views/manageSubCompanyBilling/manage-sub-company-billing/manage-sub-company-billing.component';
import { AdminPanelComponent } from './views/admin-panel/admin-panel.component';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: '/sessions/signin',
        pathMatch: 'full'
    },
    {
        path: 'sessions/signin/:hashCode',
        component: SigninComponent
    },
    // {
    //     path: '',
    //     redirectTo: '/sessions/forgot-password',
    //     pathMatch: 'full'
    // },
    {
        path: 'disclaimer',
        component: DisclaimerComponent
    },
    {
        path: 'disclaimer',
        component: DisclaimerComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: 'app/:id',
        component: MobileAppsComponent
    },
    {
        path: 'app',
        component: MobileAppsComponent
    },
    {
        path: 'activate/:response',
        component: UserActivationComponent
    },
    {
        path: 'cookies',
        component: CookiesComponent
    },
    {
        path: 'patchnotes',
        component: PatchnotesComponent
    },
    {
        path: 'gdpr',
        component: GdprComponent
    },
    {
        path: 'testcomponent',
        component: TestcomponentComponent
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
                data: { title: '' }
            }
        ]
    },
    {
        path: 'print',
        component: PrintLayoutComponent,
        children: [
            { path: 'driverreportprint', component: DriverreportPrintComponent },
            { path: 'weeklytableprint', component: WeeklyTablePrintComponent },
            { path: 'datamatchprint', component: DataMatchPrintComponent },
            { path: 'driverattestationprint', component: DriverAttestationPrintComponent },
            { path: 'checklistprint', component: ChecklistPrintComponent },
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            // {
            //     path: 'devices',
            //     loadChildren: () => import('./views/devices/devices.module').then(m => m.DevicesModule),
            //     data: { title: 'Devices', breadcrumb: 'Devices' }
            // },
            {
                path: 'devices/:imei',
                loadChildren: () => import('./views/devices/devices.module').then(m => m.DevicesModule),
                data: { title: 'Devices', breadcrumb: 'Devices' }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
                data: { title: 'Dashboard.Title', breadcrumb: 'Dashboard.Title' }
            },
            {
                path: 'drivers',
                loadChildren: () => import('./views/drivers/drivers.module').then(m => m.DriversModule),
                data: { title: 'Drivers', breadcrumb: 'Drivers' }
            },
            {
                path: 'drivers/:validity',
                loadChildren: () => import('./views/drivers/drivers.module').then(m => m.DriversModule),
                data: { title: 'Drivers', breadcrumb: 'Drivers' }
            },
            {
                path: 'drivers/:driverinfoFromLink/:driverNameFromLink',
                loadChildren: () => import('./views/drivers/drivers.module').then(m => m.DriversModule),
                data: { title: 'Drivers', breadcrumb: 'Drivers' }
            },
            {
                path: 'vehicles',
                loadChildren: () => import('./views/vehicles/vehicles.module').then(m => m.VehiclesModule),
                data: { title: 'Vehicles', breadcrumb: 'Vehicles' }
            },
            {
                path: 'vehicles/:registrNumber/:identNumber',
                loadChildren: () => import('./views/vehicles/vehicles.module').then(m => m.VehiclesModule),
                data: { title: 'Vehicles', breadcrumb: 'Vehicles' }
            },
            {
                path: 'vehicles/:validity',
                loadChildren: () => import('./views/vehicles/vehicles.module').then(m => m.VehiclesModule),
                data: { title: 'Vehicles', breadcrumb: 'Vehicles' }
            },
            {
                path: 'analysis',
                loadChildren: () => import('./views/analysis/analysis.module').then(m => m.AnalysisModule),
                data: { title: 'Analysis', breadcrumb: 'Analysis' }
            },
            // {
            //     path: 'upload',
            //     loadChildren: () => import('./views/upload/upload.module').then(m => m.UploadModule),
            //     data: { title: 'Upload', breadcrumb: 'Upload' }
            // },
            {
                path: 'release',
                loadChildren: () => import('./views/release/release.module').then(m => m.ReleaseModule),
                data: { title: 'Release', breadcrumb: 'Release' }
            },
            {
                path: 'uploaded-files',
                loadChildren: () => import('./views/uploaded-files/uploaded-files.module').then(m => m.UploadedFilesModule),
                data: { title: 'Uploaded files', breadcrumb: 'Uploaded files' }
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/settings-page.module').then(m => m.SettingsPageModule),
                data: { title: 'Settings.Title', breadcrumb: 'Settings.Title' }
            },
            {
                path: 'individualview',
                loadChildren: () => import('./views/individualview/individualview.module').then(m => m.IndividualviewModule),
                data: { title: 'Driver view', breadcrumb: 'Driver view' }
            },
            {
                path: 'individualview/:driverNameFromLink',
                loadChildren: () => import('./views/individualview/individualview.module').then(m => m.IndividualviewModule),
                data: { title: 'Driver view', breadcrumb: 'Driver view' }
            },
            {
                path: 'vehicleview',
                loadChildren: () => import('./views/vehicleview/vehicleview.module').then(m => m.VehicleviewModule),
                data: { title: 'Vehicle view', breadcrumb: 'Vehicle view' }
            },
            {
                path: 'expenses',
                loadChildren: () => import('./views/expenses/expenses.module').then(m => m.ExpensesModule),
                data: { title: 'Expenses', breadcrumb: 'Expenses' }
            },
            {
                path: 'tacholive',
                component: DrivertraceComponent,
                data: { title: 'Tacho Live', breadcrumb: 'Tacho Live' }
            },
            {
                path: 'subscription',
                component: SubscriptionComponent,
                data: { title: 'Subscription', breadcrumb: 'Subscription' }
            },
            {
                path: 'updaterchecker',
                component: UpdaterCheckerComponent,
                data: { title: 'Updaters checker', breadcrumb: 'Updaters checker' }
            },
            {
                path: 'notificationManager',
                component: NotificationManagementComponent,
                data: { title: 'Notifications', breadcrumb: 'Notifications' }
            },
            {
                path: 'manage-sub-company',
                component: ManageSubCompanyComponent,
                data: { title: 'Manage sub company', breadcrumb: 'Manage sub company' }
            },
            {
                path: 'manage-sub-company-billing',
                component: ManageSubCompanyBillingComponent,
                data: { title: 'Manage sub company-billing', breadcrumb: 'Manage sub company' }
            },
            {
                path: 'report',
                loadChildren: () => import('./views/rszoft/report/report.module').then(m => m.ReportModule),
                data: { title: 'ReportsBreadCrumb', breadcrumb: 'ReportsBreadCrumb', icon: 'route_report_icon' }
            },
            {
                path: 'admin',
                component: AdminPanelComponent,
                data: { title: 'Admin panel', breadcrumb: 'Admin panel' }
            },
            {
                path: 'logout',
                component: LogoutComponent,
                data: { title: 'LogoutAPP' }
            }
        ]
    },
    // {
    //     path: '**',
    //     redirectTo: 'sessions/404'
    // }

];


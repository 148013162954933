import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { CompanyService } from 'app/services/company/company.service';
import { CompaniesResponse, Company } from 'app/services/company/company.model';
import { Router } from '@angular/router';
import { Content, NotificationsService } from 'app/services/notifications.service';
import moment from 'app/moment.override';
type Moment = typeof moment;
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { egretAnimations } from 'app/helpers/egret-animations';
import { DateAdapter } from '@angular/material/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CURRENT_APPLICATION_VERSION, PROFILE } from 'app/helpers/globals';
import { DailyChartService } from 'app/services/rszoft/dailychart/daily-chart.service';
import { FormControl } from '@angular/forms';
import { DriverWithStatistics } from '../../../services/drivers/drivers.service';
import { debounce, takeUntil } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { Pro, ProService, ProType } from 'app/services/pro/pro.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css'],
    animations: egretAnimations
})

export class TopbarComponent implements OnInit, OnDestroy {
    @Input() sidenav:MatSidenav;
    @Input() notificPanel;
    @Input() loggedInUser;
    @Input() notifications: Content[];
    @Input() isMobile: boolean;
    @Output() onCompanyChange = new EventEmitter<any>();

    currentCompanyId: number;

    selectedCompany: Company;

    availableCompanies = [] as Company[];

    currentCompanyName: string;
    currentLangName: string;

    selectedValue: string;
    dataexists = false;

    isAdmin = false;
    profile = PROFILE;
    hasRDUAccess = JSON.parse(localStorage.getItem('loggedInUser')).hasRDUAccess;


    public filterCompany: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
    driverDataIsLoading: boolean;
    protected _onDestroy = new Subject<void>();

    currentApplicationVersion = CURRENT_APPLICATION_VERSION;

    private subscriptions: Subscription[] = [];

    pro$= this.proService.pro$;
    availableLanguages = []

    currentUserRoles$ = this._authService.currentUserRoles$;
    companyIdIsTheSameAsLoggedInUser = false;

    constructor(private companyService: CompanyService,
        private _authService: AuthService,
        private r: Router,
        private translate: TranslateService,
        private dailyChartService: DailyChartService,
        private _adapter: DateAdapter<any>,
        private readonly proService: ProService) {
    }


    ngOnInit() {
        this.selectedValue = localStorage.getItem('selectedLangCode') || 'EN';

        //subscribe to current company id
        const subscriptionOne = this._authService.currentCompanyId.subscribe(async (companyId) => {
            // console.log('TopBar:',companyId);
            this.companyIdIsTheSameAsLoggedInUser = companyId === this._authService.getLoggedInUser().clientId;
            this.currentCompanyId = companyId;
            this.getCompanies();
        });

        this.subscriptions.push(subscriptionOne);



        // this.onCompanyChange.emit(this.currentCompany);
        this.checkCurrentLangName();
        this.isAdmin = this._authService.getLoggedInUser().clientAdmin;




    }

    changeMenuSetting() {
        // console.log('changeMenuSetting', this.sidenav);
        if (this.sidenav.opened) {
            this.sidenav.close();
        } else {
            this.sidenav.open();
        }
        setTimeout(() => {                           // <<<---using () => syntax
            this.dailyChartService.menuChanged();
        }, 500);
    }

    async getCompanies() {
        this.driverDataIsLoading = true;
        const response = await this.companyService.getAllSubcompanies().toPromise();
        this.availableCompanies = response;

        this.availableCompanies.sort(function (a, b) {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        });
        // console.log('TopBar:',this.currentCompanyId);
        const selectedLocalCompany: Company = this.availableCompanies.find((aCopmany) => aCopmany.id === this.currentCompanyId);
        this.selectedCompany = selectedLocalCompany;
        localStorage.setItem('companyName', '' + selectedLocalCompany.name);
        this.driverDataIsLoading = false;

    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    checkCurrentLangName() {
        this.currentLangName = localStorage.getItem('selectedLangName');
    }

    navigate(url: string, param: string = '') {
        if(param.length > 0){
            this.r.navigate([url],{ queryParams: { p: param } });
            return;
        }
        this.r.navigate([url]);
    }

    changeLang(selectedValue) {
        this.selectedValue = selectedValue;
        // console.log(selectedValue);
        localStorage.setItem('selectedLangCode', '' + selectedValue);
        localStorage.setItem('selectedLangName', '' + this.currentLangName);
        moment.locale(localStorage.getItem('selectedLangCode'));
        this.translate.use(selectedValue);
        this._adapter.setLocale(localStorage.getItem('selectedLangCode'));

        if (selectedValue === 'HU') {
            localStorage.setItem('selectedLangName', '' + 'Hungarian');
        }
        if (selectedValue === 'EN') {
            localStorage.setItem('selectedLangName', '' + 'English');
        }
        if (selectedValue === 'RO') {
            localStorage.setItem('selectedLangName', '' + 'Romanian');
        }
        if (selectedValue === 'DE') {
            localStorage.setItem('selectedLangName', '' + 'German');
        }
        if (selectedValue === 'NL') {
            localStorage.setItem('selectedLangName', '' + 'Dutch');
        }
        if (selectedValue === 'FR') {
            localStorage.setItem('selectedLangName', '' + 'French');
        }
        if (selectedValue === 'DA') {
            localStorage.setItem('selectedLangName', '' + 'Danish');
        }
        if (selectedValue === 'ES') {
            localStorage.setItem('selectedLangName', '' + 'Spanish');
        }
        if (selectedValue === 'SL') {
            localStorage.setItem('selectedLangName', '' + 'Slovenian');
        }
        if (selectedValue === 'IT') {
            localStorage.setItem('selectedLangName', '' + 'Italian');
        }
        if (selectedValue === 'SK') {
            localStorage.setItem('selectedLangName', '' + 'Slovak');
        }
        if (selectedValue === 'BG') {
            localStorage.setItem('selectedLangName', '' + 'Bulgarian');
        }
        if (selectedValue === 'PT') {
            localStorage.setItem('selectedLangName', '' + 'Portuguese');
        }
        if (selectedValue === 'CS') {
            localStorage.setItem('selectedLangName', '' + 'Czech');
        }
        if (selectedValue === 'SV') {
            localStorage.setItem('selectedLangName', '' + 'Svenska');
        }
    }

    displayFn(item: Company): string {
        return item ? item.name : null;
    }

    elementIsPro(element:Company){
        return ['REQUESTED', 'TRIAL', 'TRIAL_PRO'].includes(element.proType);
    }

    setCompany(id: any) {
        // console.log(id)
        // this.currentCompany = id;
        this.selectedCompany = id.value;
        this.onCompanyChange.emit(id.value.id || this.currentCompanyId);
    }

    toggleNotific() {
        this.notificPanel.toggle();
    }

    trialEndDate(){
        try {
            const user = this._authService.getLoggedInUser();
            if(!user.trialEndDate){
                return '';
            }
            const endDate = new Date(user.trialEndDate??0);
            return endDate.toLocaleDateString();
        } catch (error) {
            
        }
        return '';
    }

    getStyle(){
        const hostname = window.location.hostname;
        if (this.profile === 3){
            return `signin !important`;
        }
        if(hostname.includes('localhost')){
            return `#15803d !important`;
        }
        if(hostname.includes('staging')){
            return '#fbbf24 !important';
        }
        if(hostname.includes('sandbox')){
            return '#f43f5e !important';
        }
        return '';
    }
}
